import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import EditDisabled from '../../../components/EditDisabled'
import TimezonePicker from '../../../components/TimezonePicker'
import CountryRegion from '../../../components/CountryRegion'
import Select from '../../../components/Select'
import StoreImgPicker from '../../../components/StoreImgPicker'
import AspectRatioTooltip from '../../../components/AspectRatioTooltip'
import { emailValidationPattern } from '../../../utils/utils'
import SubmitWithHint, {
  defaultRequiredMessage,
} from '../../../components/SubmitWithHint'
import { Overlay } from '../../../styles/styled'
import ProgressIndicator from '../../../components/ProgressIndicator'
import BackupKeysForStore, {
  CheckboxInputContainer,
} from '../../../components/BackupKeysForStore'
import { DeviceConfigurationList } from '../../../components/DeviceConfiguration'
import ProvisioningButton from '../../../components/Provisioning'

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

export const Header = styled.h5`
  padding-top: 10px;
  padding-bottom: 10px;
`

export const ModalBody = styled.div`
  padding: 10px;
`

export const ColumnContainer = styled.div`
  padding-top: 0px;
`
class CreateEditStoreScreen extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      isRemoving: false,
      showValidationHint: false,
    }
    this.validationRefs = []
  }

  componentWillUnmount() {
    this.validationRefs = []
  }

  setServerErrorDismiss(element) {
    const $element = $(element) // eslint-disable-line
    $element.keyup(() => {
      element.setCustomValidity('')
      $element.attr('data-content', '')
      $element.popover('hide')
    })
  }

  popoverOnElement($element, msg) {
    $element.attr('data-placement', 'bottom')
    $element.attr('data-content', msg)
    $element.popover('show')
    setTimeout(() => {
      $element.attr('data-content', '')
      $element.popover('disable')
    }, 3000)
  }

  setServerError(element, msg, name) {
    if (!element) {
      return
    }

    if (element.isCustom && element.setCustomValidity) {
      if (element.popoverElement) {
        this.popoverOnElement(element.popoverElement(), msg)
      } else {
        element.setCustomValidity(msg, name)
      }
      return
    }

    element.setCustomValidity(msg)
    const $element = $(element) // eslint-disable-line
    this.popoverOnElement($element, msg)
  }

  addValidationRef = (ref, name) => {
    this.validationRefs.push({ ref, name })
  }

  setValidationErrors(validationErrors) {
    this.validationRefs.forEach(({ ref, name }) => {
      const error = validationErrors[name]
      if (error) {
        this.setServerError(ref, error[0], name)
      }
    })
  }

  setServerValidity(error) {
    if (error && error.error) {
      const { validationErrors } = error.error
      if (validationErrors) {
        this.setState({ serverValidationFailed: true })
        this.setValidationErrors(validationErrors)
      } else {
        // another server error
        console.log(error)
      }
    }
  }

  keyTypeMappings = {
    'VERSA': ['VERSA'],
    'VERSA, BLE': ['VERSA', 'BLE'],
    'VERSA, NFC': ['VERSA', 'NFC']
  }

  // Normalize the allowedKeyTypes array.
  getAllowedKeyTypesValue = () => {
    let keyTypes = this.props.store.allowedKeyTypes || this.props.organization.allowedKeyTypes || ['VERSA'] // Default to Org's setting or VERSA.
    const order = ['VERSA', 'BLE', 'NFC']
    let sortedKeyTypes = [...keyTypes].sort((a, b) => order.indexOf(a) - order.indexOf(b)); // Sort according to order.
    return sortedKeyTypes.join(', ') // Return joined array.
  }

  handleDropdownChange = (e) => {
    const selectedLabel = e.target.value
    const updatedAllowedKeyTypes = this.keyTypeMappings[selectedLabel]

    this.props.onChange({ allowedKeyTypes: updatedAllowedKeyTypes })
  }

  keyTypeOptions = [
    { value: "VERSA", label: "VERSA" },
    { value: "VERSA, BLE", label: "VERSA, BLE" },
    { value: "VERSA, NFC", label: "VERSA, NFC" },
  ]

  /**
   * Client side validation for custom components
   *
   * @returns {boolean}
   */
  isValid = () => {
    const storeImgIsValid = this.storeImg.isValid()
    const customFieldsIsValid = storeImgIsValid

    if (this.formRef.checkValidity() && customFieldsIsValid) {
      this.setState({ showValidationHint: false })
      return true
    } else {
      this.formRef.reportValidity()
      this.setState({ showValidationHint: true })
      return false
    }
  }

  highlightInvalid = () => {
    if (!this.storeImg.isValid()) {
      this.storeImg.showInvalidMessage()
    }
  }

  close = () => {
    const { id, onClose } = this.props
    $(`#modal-${id}`).modal('hide') // eslint-disable-line
    onClose()
  }

  renderMainContent = () => {
    const {
      store,
      organization,
      regions,
      onChange,
      storeEditLoading,
      hasMtiAdminRole,
      hasOrganizationAdminRole,
      hasRegionalManagerRole,
      hasStoreManagerRole,
    } = this.props
    const isRegionStatic =
      hasStoreManagerRole &&
      !hasRegionalManagerRole &&
      !hasOrganizationAdminRole &&
      !hasMtiAdminRole
    return (
      <div className="col-xs-6">
        <div className="form-row">
          <ColumnContainer className="form-group col-md-8 bmd-form-group">
            <Column>
              <div>Store Name</div>
              <input
                ref={(ref) => {
                  this.storeName = ref
                  this.setServerErrorDismiss(ref)
                  this.addValidationRef(this.storeName, 'name')
                }}
                type="text"
                className="form-control"
                id="storeName"
                placeholder=""
                required="required"
                defaultValue={store.name}
                onChange={(e) => onChange({ name: e.target.value })}
                onInvalid={defaultRequiredMessage}
                onInput={defaultRequiredMessage}
              />
              <div className="invalid-feedback">Please enter a valid name.</div>
            </Column>
          </ColumnContainer>
          <ColumnContainer className="form-group col-md-4 bmd-form-group">
            <Column>
              <div>Store Id</div>
              <input
                ref={(ref) => {
                  this.branchCode = ref
                  this.setServerErrorDismiss(ref)
                }}
                type="text"
                className="form-control"
                id="branchCode"
                placeholder=""
                required="required"
                defaultValue={store.branchCode}
                onChange={(e) => onChange({ branchCode: e.target.value })}
                onInvalid={defaultRequiredMessage}
                onInput={defaultRequiredMessage}
              />
              <div className="invalid-feedback">Store Id required.</div>
            </Column>
          </ColumnContainer>
        </div>

        <div className="form-row">
          <ColumnContainer className="form-group col-md-8 bmd-form-group">
            <EditDisabled
              header={'Organization'}
              text={organization ? organization.name : ''}
            />
          </ColumnContainer>
          <ColumnContainer className="form-group col-md-4 bmd-form-group">
            <Column>
              <div>Store phone</div>
              <input
                ref={ref => this.setServerErrorDismiss(ref)}
                type="text"
                id="storePhone"
                placeholder=""
                className="form-control"
                defaultValue={store.storePhone}
                onChange={e => onChange({ storePhone: e.target.value })}
              />
            </Column>
          </ColumnContainer>
        </div>

        <div className="form-row">
          <ColumnContainer className="form-group col-md-4 bmd-form-group">
            <Column>
              <div>Contact name</div>
              <input
                ref={(ref) => {
                  this.contactName = ref
                  this.setServerErrorDismiss(ref)
                  this.addValidationRef(this.contactName, 'contactName')
                }}
                type="text"
                className="form-control"
                id="contactName"
                placeholder=""
                required="required"
                defaultValue={store.contactName}
                onChange={(e) => onChange({ contactName: e.target.value })}
                onInvalid={defaultRequiredMessage}
                onInput={defaultRequiredMessage}
              />
              <div className="invalid-feedback">
                Please enter a valid contact name.
              </div>
            </Column>
          </ColumnContainer>
          <ColumnContainer className="form-group col-md-4 bmd-form-group">
            <Column>
              <div>Contact email</div>
              <input
                ref={(ref) => {
                  this.contactEmail = ref
                  this.setServerErrorDismiss(ref)
                  this.addValidationRef(this.contactEmail, 'contactEmail')
                }}
                type="email"
                className="form-control"
                id="contactEmail"
                placeholder=""
                required="required"
                pattern={emailValidationPattern}
                onKeyUp={() =>
                  (this.contactEmail.value = this.contactEmail.value.toLowerCase())
                }
                defaultValue={store.contactEmail}
                onChange={(e) => onChange({ contactEmail: e.target.value })}
                onInvalid={defaultRequiredMessage}
                onInput={defaultRequiredMessage}
              />
              <div className="invalid-feedback">
                Please enter a valid contact email.
              </div>
            </Column>
          </ColumnContainer>
          <ColumnContainer className="form-group col-md-4 bmd-form-group">
            <Column>
              <div>Contact phone</div>
              <input
                ref={ref => this.setServerErrorDismiss(ref)}
                type="text"
                id="contactPhone"
                placeholder=""
                required="required"
                defaultValue={store.contactPhone}
                onChange={e => onChange({ contactPhone: e.target.value })}
              />
            </Column>
          </ColumnContainer>
        </div>

        <div className="form-row">
          <ColumnContainer className="form-group col-md-4 bmd-form-group">
            {isRegionStatic ? (
              <EditDisabled
                header={'Region'}
                text={
                  (regions.find((r) => r.id === store.regionId) || {}).name ||
                    ''
                }
              />
            ) : (
              <Column>
                <div>Region</div>
                <Select
                  ref={() => {
                    const hasRegionDefined = !!(
                      regions.find((r) => r.id === store.regionId) || {}
                    ).id
                    if (hasRegionalManagerRole && !hasRegionDefined) {
                      onChange({ regionId: (regions[0] || {}).id })
                    }
                  }}
                  name={'region'}
                  value={
                    (regions.find((r) => r.id === store.regionId) || {}).id
                  }
                  onChange={(e) => onChange({ regionId: e.target.value })}
                >
                  {!hasRegionalManagerRole && (
                    <option value="" id="noRegion">
                      Select Region...
                    </option>
                  )}
                  {regions.map((r) => (
                    <option key={r.id} value={r.id}>
                      {r.name}
                    </option>
                  ))}
                </Select>
              </Column>
            )}
          </ColumnContainer>
          <ColumnContainer className="form-group col-md-4 bmd-form-group">
            <Column>
              <div>Time zone</div>
              <TimezonePicker
                ref={(ref) => {
                  this.timeZone = ref
                  this.addValidationRef(this.timeZone, 'timeZone')
                  this.setServerErrorDismiss(ref)
                }}
                id="timeZone"
                required="required"
                defaultValue={store.timeZone}
                value={store.timeZone}
                onChange={(e) => onChange({ timeZone: e.value })}
                onInvalid={defaultRequiredMessage}
                onInput={defaultRequiredMessage}
              />
            </Column>
          </ColumnContainer>
          {false && (
            <ColumnContainer className="form-group col-md-4 bmd-form-group">
              <Column>
                <CheckboxInputContainer editable>
                  <BackupKeysForStore
                    name="backup-keys-for-store"
                    checked={store.backupKeyEnabled}
                    onChange={(value) => onChange({ backupKeyEnabled: value })}
                  />
                </CheckboxInputContainer>
              </Column>
            </ColumnContainer>
          )}
        </div>
        {storeEditLoading() && (
          <React.Fragment>
            <Overlay />
            <ProgressIndicator />
          </React.Fragment>
        )}
      </div>
    )
  }

  renderAddresses = () => {
    const { store, onChange } = this.props
    return (
      <div className="col-xs-6">
        <div className="form-row">
          <CountryRegion
            ref={(ref) => {
              this.countryRegion = ref
              this.setServerErrorDismiss(ref)
              this.addValidationRef(this.countryRegion, 'address.state')
              this.addValidationRef(this.countryRegion, 'address.country')
            }}
            id="countryRegion"
            requiredCountry="required"
            requiredRegion=""
            defaultValueCountry={
              (store.address || {}).country || 'United States'
            }
            defaultValueRegion={(store.address || {}).state}
            valueCountry={(store.address || {}).country || 'United States'}
            valueRegion={(store.address || {}).state}
            container={Column}
            country={(store.address || {}).country}
            region={(store.address || {}).state}
            selectCountry={(country) => onChange({ address: { country } })}
            selectRegion={(region) => onChange({ address: { state: region } })}
          />
        </div>

        <div className="form-row">
          <ColumnContainer className="form-group col-md-8 bmd-form-group">
            <Column>
              <div>Address 1</div>
              <input
                ref={(ref) => {
                  this.address1 = ref
                  this.setServerErrorDismiss(ref)
                  this.addValidationRef(this.address1, 'address.address1')
                }}
                type="text"
                className="form-control"
                id="address1"
                placeholder=""
                required="required"
                defaultValue={(store.address || {}).address1}
                onChange={(e) =>
                  onChange({ address: { address1: e.target.value } })
                }
                onInvalid={defaultRequiredMessage}
                onInput={defaultRequiredMessage}
              />
              <div className="invalid-feedback">
                Please enter a valid address.
              </div>
            </Column>
          </ColumnContainer>
          <ColumnContainer className="form-group col-md-4 bmd-form-group">
            <Column>
              <div>City</div>
              <input
                ref={(ref) => {
                  this.city = ref
                  this.setServerErrorDismiss(ref)
                  this.addValidationRef(this.city, 'address.city')
                }}
                type="text"
                className="form-control"
                id="city"
                placeholder=""
                required="required"
                defaultValue={(store.address || {}).city}
                onChange={(e) =>
                  onChange({ address: { city: e.target.value } })
                }
                onInvalid={defaultRequiredMessage}
                onInput={defaultRequiredMessage}
              />
              <div className="invalid-feedback">Please enter a valid city.</div>
            </Column>
          </ColumnContainer>
        </div>

        <div className="form-row">
          <ColumnContainer className="form-group col-md-8 bmd-form-group">
            <Column>
              <div>Address 2</div>
              <input
                ref={(ref) => {
                  this.address2 = ref
                  this.setServerErrorDismiss(ref)
                }}
                type="text"
                className="form-control"
                id="address2"
                placeholder=""
                required=""
                defaultValue={(store.address || {}).address2}
                onChange={(e) =>
                  onChange({ address: { address2: e.target.value } })
                }
                onInvalid={defaultRequiredMessage}
                onInput={defaultRequiredMessage}
              />
            </Column>
          </ColumnContainer>
        </div>

        <div className="form-row">
          <ColumnContainer className="form-group col-md-8 bmd-form-group">
            <Column>
              <div>Address 3</div>
              <input
                ref={(ref) => {
                  this.address3 = ref
                  this.setServerErrorDismiss(ref)
                }}
                type="text"
                className="form-control"
                id="address3"
                placeholder=""
                required=""
                defaultValue={(store.address || {}).address3}
                onChange={(e) =>
                  onChange({ address: { address3: e.target.value } })
                }
                onInvalid={defaultRequiredMessage}
                onInput={defaultRequiredMessage}
              />
            </Column>
          </ColumnContainer>
          <ColumnContainer className="form-group col-md-4 bmd-form-group">
            <Column>
              <div>Postal code</div>
              <input
                ref={(ref) => {
                  this.zip = ref
                  this.setServerErrorDismiss(ref)
                  this.addValidationRef(this.zip, 'address.zip')
                }}
                type="text"
                className="form-control"
                id="zip"
                placeholder=""
                required="required"
                defaultValue={(store.address || {}).zip}
                onChange={(e) => onChange({ address: { zip: e.target.value } })}
                onInvalid={defaultRequiredMessage}
                onInput={defaultRequiredMessage}
              />
              <div className="invalid-feedback">
                Please enter a valid postal code.
              </div>
            </Column>
          </ColumnContainer>
        </div>
      </div>
    )
  }

  render() {
    const {
      store: { imageUrl, id: storeId },
      onConfirm,
      isSaving,
      onRemoveAll,
      onChange,
      title,
    } = this.props
    const { isRemoving } = this.state
    return (
      <div className="modal-content">
        <form
          className="needs-validation was-validated"
          ref={(ref) => (this.formRef = ref)}
          noValidate
          onSubmit={(e) => {
            e.preventDefault()
            // Custom components validation check
            if (this.isValid()) {
              onConfirm()
              this.setState({ serverValidationFailed: false })
            } else {
              this.highlightInvalid()
            }
          }}
        >
          <div className="modal-header border-bottom">
            <h5 className="modal-title">{title}</h5>
            <div>
              {isSaving ? (
                <button type="button" className="btn btn-primary" disabled>
                  Saving
                </button>
              ) : (
                <SubmitWithHint
                  text={storeId ? 'Save Store' : 'Create Store'}
                  showHint={this.state.showValidationHint}
                  onClick={this.isValid}
                  onFocus={this.isValid}
                  className="btn btn-primary btn-raised"
                />
              )}
              <button
                type="button"
                className="btn btn-primary ml-2"
                onClick={this.close}
              >
                Cancel
              </button>
            </div>
          </div>
          {isRemoving ? (
            <div
              ref={() => {
                if (this.state.isRemoving) this.setState({ isRemoving: false })
              }}
            />
          ) : (
            <div>
              <AspectRatioTooltip
                ratio={16 / 5}
                ratioText="16:5"
                render={(ratioTooltip) => (
                  <StoreImgPicker
                    ref={(ref) => {
                      this.storeImg = ref
                      this.setServerErrorDismiss(ref)
                    }}
                    defaultValue={imageUrl}
                    onChange={({ imageUrl, image }) =>
                      onChange({ imageUrl, image })
                    }
                    ratioTooltip={ratioTooltip}
                  />
                )}
              />
              <ModalBody className="modal-body">
                {this.renderMainContent()}
              </ModalBody>
              <ModalBody className="modal-body">
                {this.renderAddresses()}
              </ModalBody>
              {storeId && <ModalBody className="modal-body">
                            <DeviceConfigurationList
                              resourceType="Store"
                              resourceId={storeId}
                            />
                          </ModalBody>}
              {storeId && <ProvisioningButton storeId={storeId} />}
            </div>
          )}
          {onRemoveAll ? (
            <ModalBody>
              <button
                type="button"
                className="btn btn-primary ml-auto"
                onClick={() => {
                  onRemoveAll()
                  this.setState({ isRemoving: true })
                }}
              >
                Remove All
              </button>
            </ModalBody>
          ) : null}
        </form>
      </div>
    )
  }
}

CreateEditStoreScreen.propTypes = {
  id: PropTypes.string.isRequired,
  store: PropTypes.object.isRequired,
  regions: PropTypes.array,
  organization: PropTypes.object,
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  isSaving: PropTypes.bool,
  onRemoveAll: PropTypes.func,
  storeEditLoading: PropTypes.func,
  title: PropTypes.string.isRequired,
  hasMtiAdminRole: PropTypes.bool,
  hasOrganizationAdminRole: PropTypes.bool,
  hasRegionalManagerRole: PropTypes.bool,
  hasStoreManagerRole: PropTypes.bool,
}

export default CreateEditStoreScreen
