import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import styles from '../../styles/App.css'

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 120px;
`

export const TextTop = styled.span`
  font-size: 14px;
  text-align: left;
  width: 120px;
`

export const TextBottom = styled.span`
  font-size: 12px;
  text-align: left;
  width: 120px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`

class UserKey extends React.PureComponent {
  render() {
    const { userId, userName, keySerialNumber, onProfile } = this.props

    return (
      <Column>
        {userId && <TextTop>
          <Link
            className={styles.link}
            to={'#'}
            onClick={() => onProfile(userId)}
          >
            {userName}
          </Link>
        </TextTop>}
        <TextBottom>{keySerialNumber}</TextBottom>
      </Column>
    )
  }
}

UserKey.propTypes = {
  userId: PropTypes.number,
  userName: PropTypes.string,
  keySerialNumber: PropTypes.string,
  onProfile: PropTypes.func.isRequired,
}

export default UserKey
