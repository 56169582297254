import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {
  createSmartStoreLayoutLink,
  createStoreSecuredProductsLink,
  createStoreTableViewLink,
  createStoreOrphanedDevicesLink,
} from '../../../utils/mtiUtils'
import FloorsSelection from '../../../components/FloorsSelection'
import EditDisabled from '../../../components/EditDisabled'
import { StoreImageContainer } from '../../../components/StoreImgPicker'
import { showHidablePopover } from '../../../utils/utils'
import BackupKeysForStore, {
  CheckboxInputContainer,
} from '../../../components/BackupKeysForStore'
import { DeviceConfigurationList } from '../../../components/DeviceConfiguration'
import ProvisioningButton from '../../../components/Provisioning'

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

export const Header = styled.h5`
  padding-top: 10px;
  padding-bottom: 10px;
`

export const ModalBody = styled.div`
  padding: 10px 10px 10px 10px;
`

export const ColumnContainer = styled.div`
  padding-top: 0px;
`

class StoreDetailsScreen extends React.Component {
  /* eslint-disable no-undef */
  createLayoutLink = () => {
    const { id, store, onRedirectToLayout } = this.props
    const { path, text } = createSmartStoreLayoutLink(store)

    return (
      <button
        className="btn btn-primary"
        name="storeLayout"
        onClick={() => {
            $(`#modal-${id}`).modal('hide') // eslint-disable-line
            onRedirectToLayout(path)
        }}
      >
        {text}
      </button>
    )
  }

  createSecuredProductsLink = () => {
    const { id, store, onRedirectToSecuredProducts } = this.props
    const { path, text } = createStoreSecuredProductsLink(store)

    return (
      <button
        className="btn btn-primary"
        name="storeSecuredProducts"
        onClick={() => {
            $(`#modal-${id}`).modal('hide') // eslint-disable-line
            onRedirectToSecuredProducts(path)
        }}
      >
        {text}
      </button>
    )
  }

  tableViewLink = () => {
    const { id, store, onRedirectToTableView } = this.props
    const { path, text } = createStoreTableViewLink(store)

    return (
      <button
        className="btn btn-primary"
        name="storeTableView"
        onClick={() => {
            $(`#modal-${id}`).modal('hide') // eslint-disable-line
            onRedirectToTableView(path)
        }}
      >
        {text}
      </button>
    )
  }

  orphanedDevicesLink = () => {
    const { id, store, onRedirectToOrphanedDevices } = this.props
    const { path, text } = createStoreOrphanedDevicesLink(store)

    return (
      <button
        className="btn btn-primary"
        name="storeOrphanedDevices"
        onClick={() => {
            $(`#modal-${id}`).modal('hide') // eslint-disable-line
            onRedirectToOrphanedDevices(path)
        }}
      >
        {text}
      </button>
    )
  }

  renderMainContent = () => {
    const { store, organization } = this.props
    let branchCodeElement
    let branchCodeHtml = store.branchCode || ''

    // Normalize the allowedKeyTypes array.
    const getAllowedKeyTypesValue = () => {
      let keyTypes = store.allowedKeyTypes || organization.allowedKeyTypes || ['VERSA'] // Default to Org's setting or VERSA.
      const order = ['VERSA', 'BLE', 'NFC']
      keyTypes.sort((a, b) => order.indexOf(a) - order.indexOf(b)); // Sort according to order.
      return keyTypes.join(', ') // Return joined array.
    }

    return (
      <div className="col-xs-6">
        <div className="form-row">
          <ColumnContainer className="form-group col-md-8 bmd-form-group">
            <EditDisabled header={'Store name'} text={store.name || ''} />
          </ColumnContainer>
          <ColumnContainer className="form-group col-md-4 bmd-form-group">
            <div
              ref={ref => (branchCodeElement = ref)}
              style={{ overflow: 'hidden' }}
              role="button"
              tabIndex="-1"
              onClick={() =>
                showHidablePopover(branchCodeElement, branchCodeHtml, 'focus')
              }
              onMouseEnter={() =>
                showHidablePopover(branchCodeElement, branchCodeHtml, 'hover')
              }
            >
              <EditDisabled header={'Store Id'} text={store.branchCode || ''} />
            </div>
          </ColumnContainer>
        </div>

        <div className="form-row">
          <ColumnContainer className="form-group col-md-8 bmd-form-group">
            <EditDisabled
              header={'Organization'}
              text={organization ? organization.name : 'Loading'}
            />
          </ColumnContainer>
          <ColumnContainer className="form-group col-md-4 bmd-form-group">
            <EditDisabled
              header={'Store phone'}
              text={store.storePhone || ''}
            />
          </ColumnContainer>
        </div>

        <div className="form-row">
          <ColumnContainer className="form-group col-md-4 bmd-form-group">
            <EditDisabled
              header={'Contact name'}
              text={store.contactName || ''}
            />
          </ColumnContainer>
          <ColumnContainer className="form-group col-md-4 bmd-form-group">
            <EditDisabled
              header={'Contact email'}
              text={store.contactEmail || ''}
            />
          </ColumnContainer>
          <ColumnContainer className="form-group col-md-4 bmd-form-group">
            <EditDisabled
              header={'Contact phone'}
              text={store.contactPhone || ''}
            />
          </ColumnContainer>
        </div>

        <div className="form-row">
          <ColumnContainer className="form-group col-md-4 bmd-form-group">
            <EditDisabled header={'Region'} text={store.regionName || ''} />
          </ColumnContainer>
          <ColumnContainer className="form-group col-md-4 bmd-form-group">
            <EditDisabled header={'Time zone'} text={store.timeZone || ''} />
          </ColumnContainer>
          {false && (
             <ColumnContainer className="form-group col-md-4 bmd-form-group">
               <CheckboxInputContainer>
                 <BackupKeysForStore
                   name="backup-keys-for-store"
                   checked={store.backupKeyEnabled}
                   disabled
                 />
               </CheckboxInputContainer>
             </ColumnContainer>
          )}
        </div>
      </div>
    )
  }

  renderAddresses = () => {
    const { store: { address = {} } } = this.props
    return (
      <div className="col-xs-6">
        <div className="form-row">
          <ColumnContainer className="form-group col-md-8 bmd-form-group">
            <EditDisabled header={'Address 1'} text={address.address1 || ''} />
          </ColumnContainer>
          <ColumnContainer className="form-group col-md-4 bmd-form-group">
            <EditDisabled header={'City'} text={address.city || ''} />
          </ColumnContainer>
        </div>

        <div className="form-row">
          <ColumnContainer className="form-group col-md-8 bmd-form-group">
            <EditDisabled header={'Address 2'} text={address.address2 || ''} />
          </ColumnContainer>
          <ColumnContainer className="form-group col-md-4 bmd-form-group">
            <EditDisabled
              header={'State/Province'}
              text={address.state || ''}
            />
          </ColumnContainer>
        </div>

        <div className="form-row">
          <ColumnContainer className="form-group col-md-5 bmd-form-group">
            <EditDisabled header={'Address 3'} text={address.address3 || ''} />
          </ColumnContainer>
          <ColumnContainer className="form-group col-md-5 bmd-form-group">
            <EditDisabled header={'Country'} text={address.country || ''} />
          </ColumnContainer>
          <ColumnContainer className="form-group col-md-2 bmd-form-group">
            <EditDisabled header={'Postal code'} text={address.zip || ''} />
          </ColumnContainer>
        </div>
      </div>
    )
  }

  renderFloors = () => {
    const { store: { floors = [] }, onClickFloor } = this.props
    const orderedFloors = floors.sort((a, b) => a.order - b.order)
    return (
      <FloorsSelection onClickFloor={onClickFloor} floors={orderedFloors} />
    )
  }

  renderTags = (taggings, id, onEditTags, hideButtons) => (
    <div>
      <div className="form-inline">
        {((taggings || [])[0] || []).map(tagging => {
           const { id: taggingId, tag: [tag: {}] } = tagging || { }
           return (
             <div className="mr-3" key={taggingId}>
               <ColumnContainer>
                 <EditDisabled text={tag.name} />
               </ColumnContainer>
             </div>
           )
        })}
      </div>
      <br />
      {!hideButtons && (
         <button
           type="button"
           className="btn btn-primary btn-raised"
           onClick={() => {
               $(`#modal-${id}`).modal('hide') // eslint-disable-line
               onEditTags()
           }}
           >
           Edit Tags
         </button>
      )}
    </div>
  )

  render() {
    const {
      id,
      onEditStorePressed,
      onClose,
      onRedirectToLayout,
      onRedirectToSecuredProducts,
      store: { name, tags, imageUrl, id: storeId },
      onEditTags,
      hasListTaggingsPermission,
      hideButtons,
    } = this.props
    return (
      <div className="modal-content">
        <div className="modal-header border-bottom">
          <h5 className="modal-title" id="exampleModalLongTitle">
            {name}
          </h5>
          <div>
            {!hideButtons && (
               <React.Fragment>
                 {this.tableViewLink()}
                 {onRedirectToLayout ? this.createLayoutLink() : null}
                 {onRedirectToSecuredProducts
                  ? this.createSecuredProductsLink()
                  : null}
                 <button
                   type="button"
                   className="btn btn-primary"
                   name="editDetails"
                   onClick={() => {
                       $(`#modal-${id}`).modal('hide') // eslint-disable-line
                       onEditStorePressed()
                   }}
                 >
                   Edit Details
                 </button>
               </React.Fragment>
            )}
            <button
              type="button"
              className="btn btn-primary btn-raised"
              name="close"
              onClick={() => {
                  $(`#modal-${id}`).modal('hide') // eslint-disable-line
                  onClose()
              }}
            >
              Close
            </button>
          </div>
        </div>
        <StoreImageContainer imageUrl={imageUrl} />
        <ModalBody className="modal-body">{this.renderMainContent()}</ModalBody>
        <ModalBody className="modal-body">{this.renderAddresses()}</ModalBody>
        <ModalBody className="modal-body">
          <DeviceConfigurationList resourceType="Store" resourceId={storeId} />
        </ModalBody>
        <ProvisioningButton storeId={storeId} />
        <div className="modal-header border-bottom">
          <Header className="modal-title">Floors</Header>
        </div>
        <ModalBody className="modal-body">{this.renderFloors()}</ModalBody>
        {hasListTaggingsPermission && (
           <div className="modal-header border-bottom">
             <Header className="modal-title">Tags</Header>
           </div>
        )}
        {hasListTaggingsPermission && (
           <ModalBody className="modal-body">
             {this.renderTags(tags, id, onEditTags, hideButtons)}
           </ModalBody>
        )}
      </div>
    )
  }
}

StoreDetailsScreen.propTypes = {
  id: PropTypes.string.isRequired,
  store: PropTypes.object.isRequired,
  onEditStorePressed: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onEditTags: PropTypes.func.isRequired,
  hasListTaggingsPermission: PropTypes.bool,
  onRedirectToLayout: PropTypes.func,
  onRedirectToSecuredProducts: PropTypes.func,
  onRedirectToTableView: PropTypes.func,
  onRedirectToOrphanedDevices: PropTypes.func,
  organization: PropTypes.object,
  hideButtons: PropTypes.bool,
  onClickFloor: PropTypes.func,
}

export default StoreDetailsScreen
