import { Record, List } from 'immutable'
import moment from 'moment'
import {
  kebabToUppercase,
  normalizeSentence,
} from '../../utils/utils'
import { extractStoreName } from './utils'
import { isOldRule } from '../../utils/mtiUtils'
import { formattedDurationWithMilliseconds } from '../ExceptionsPage/utils'

export const Report = new Record({
  id: null,

  userId: null,
  userName: null,
  // key
  keyId: null,
  keySerialNumber: null,

  eventTypeMessage: null,
  eventType: null,
  noncompliantReason: null,

  occurredAt: null,

  duration: null,

  storeId: null,
  storeDescription: null,

  fixtureId: null,
  fixtureName: null,

  resourceType: null,
  resourcePrimaryInfo: null,
  resourceSecondaryInfo: null,
  resourceInfoDetails: null,

  storeName: null,
  positionName: null,

  // user for modal
  user: null,
})

export function createReports(results) {
  return List(
    (results || []).filter(filterReports).map(data => {
      const occurredAt = data.occurred_at
      const user = data.resource_owner || {}

      const storeTimeZone = data.store.time_zone
      const occurredAtInStoreTimezone =
        occurredAt &&
        moment.tz(occurredAt, storeTimeZone).format('MM/DD/YYYY H:mm')

      const resource = data.resource || {}
      const resourceAttributes = {
        resourcePrimaryInfo: null,
        resourceSecondaryInfo: null,
        resourceInfoDetails: null,
      }
      switch (data.resource_type) {
        case 'SecurityDevice':
          resourceAttributes.resourcePrimaryInfo = resource.device_type
          resourceAttributes.resourceSecondaryInfo = data.position_name
          resourceAttributes.resourceInfoDetails = `Security Device (…${resource.serial_number.substr(
            -8
          )}) \
             on position "${data.position_name}"`
          if (data.product_name) {
            resourceAttributes.resourceInfoDetails = `${
              resourceAttributes.resourceInfoDetails
            } \
              securing "${data.product_name}"`
          }
          break

        case 'ComplianceRule':
          /* ComplianceRule#type is of the form
           *   Compliance::Rules::Product
           * or
           *   Compliance::Rules::SecurityDeviceType
           */
          resourceAttributes.resourcePrimaryInfo = `${resource.type
            .split('::')
            .pop()} Rule`
          resourceAttributes.resourceSecondaryInfo = {
            Position: data.position_name,
            Fixture: data.fixture_name,
            Store: data.store_name,
          }[resource.regulable_type]

          resourceAttributes.resourceInfoDetails = [
            resource.regulable_type,
            `${resourceAttributes.resourceSecondaryInfo}:`,
            resource.status === 1
              ? `${normalizeSentence(resource.description)} ✓`
              : normalizeSentence(resource.action),
          ].join(' ')
          break
        case 'PhysicalKey':
          resourceAttributes.resourcePrimaryInfo = 'Physical Key'
          resourceAttributes.resourceSecondaryInfo = resource
          resourceAttributes.resourceInfoDetails = `Physical Key in store ${
            data.store_name
          }`
          break
        case 'User':
          resourceAttributes.resourcePrimaryInfo = data.resource_type
          resourceAttributes.resourceSecondaryInfo = `${data.resource.first_name} ${data.resource.last_name}`
          resourceAttributes.resourceInfoDetails = data.resource.email
          break
        case 'Position':
          resourceAttributes.resourceInfoDetails = `Position on fixture "${
            data.fixture_name
          }"`
          if (data.product_name) {
            resourceAttributes.resourceInfoDetails = `${
              resourceAttributes.resourceInfoDetails
            } \
              securing "${data.product_name}"`
          }
        // fall through
        case 'Fixture':
          resourceAttributes.resourceInfoDetails =
            resourceAttributes.resourceInfoDetails ||
            `Fixture in store "${data.store_name}"`
        // fall through
        case 'Store':
          resourceAttributes.resourceInfoDetails =
            resourceAttributes.resourceInfoDetails ||
            `Store "${data.store_name}"`
        // fall through
        default:
          resourceAttributes.resourcePrimaryInfo = data.resource_type
          resourceAttributes.resourceSecondaryInfo =
            resource.name || data.position_name
          resourceAttributes.resourceInfoDetails =
            resourceAttributes.resourceInfoDetails ||
            [data.store_name, data.fixture_name, data.position_name]
              .filter(x => !!x)
              .join(' / ')
      }

      const attrs = {
        id: data.id,

        // column User/Key
        // user
        userId: data.user_id,
        userName: `${user.first_name || ''} ${user.last_name || ''}`, // for operational reports
        // key
        keyId: data.physical_key_id,
        keySerialNumber: data.physical_key_serial_number, // for operational reports

        // column Occurred at (Date)
        occurredAt: occurredAtInStoreTimezone,

        // column Event type/Event message
        eventTypeMessage: eventTypeMessage(data),
        eventType: data.event_type,

        // column Duration
        duration: formattedDurationWithMilliseconds(
          data.duration * 1000,
          milliseconds(data.formatted_duration)
        ),

        // column Store
        storeId: data.store_id,
        storeDescription: data.store_name,

        // column Fixture
        fixtureId: data.fixture_id,
        fixtureName: data.fixture_name,

        // column Resource type/Resource
        resourceType: data.resource_type,

        positionName: data.position_name,
        storeName: extractStoreName(data.store_name),

        ...resourceAttributes,
        // user for modal
        user: {
          id: user.id,
          firstName: user.first_name,
          lastName: user.last_name,
          phoneNumber: user.phone_number,
          email: user.email,
          employeeIdentifier: user.employee_identifier,
          avatarUrl: user.employee_url,
          roleKey: user.roles_cache && user.roles_cache[0] && user.roles_cache[0].name,
          resourceIds: user.roles_cache && user.roles_cache.map(rc => rc.resource_id),
        },
      }

      if (data.reportyType === 'compliance') {
        attrs.noncompliantReason = (data.resource || {}).noncompliant_reason
      } else if (data.reportyType === 'health') {
        attrs.noncompliantReason = data.event_message
      }
      return new Report(attrs)
    })
  )
}

function milliseconds(formattedDuration) {
  if (!formattedDuration) {
    return 0
  }

  const parts = formattedDuration.split('.')
  if (parts.length < 2) {
    return 0
  }

  return parts[1]
}

function filterReports(report) {
  if (
    report.fixture &&
    isOldRule(report.fixture, 'noncompliant_reason') &&
    report.reportyType === 'compliance'
  ) {
    return false
  }

  if (!report.rule_attribute) {
    return true
  }

  return report.rule_attribute !== 'layout_size'
}

function eventTypeMessage(data) {
  return kebabToUppercase(data.event_type)
}
